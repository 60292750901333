import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardContent } from "./components/ui/card";
import { Button } from "./components/ui/button";
import { Input } from "./components/ui/input";
import { Slider } from "./components/ui/slider";
import { Checkbox } from "./components/ui/checkbox";

const PricingCalculatorWizardAllFeatures = () => {
  const [step, setStep] = useState(0);
  const [airTransactions, setAirTransactions] = useState(0);
  const [hotelTransactions, setHotelTransactions] = useState(0);
  const [estimatedPNRs, setEstimatedPNRs] = useState(0);
  const [tier, setTier] = useState('');
  const [selectedFeatures, setSelectedFeatures] = useState({});

  const features = [
    { name: 'Approvals', prices: [0.50, 0.35, 0.25] },
  ];

  useEffect(() => {
    const totalPNRs = airTransactions + (hotelTransactions * 0.6);
    setEstimatedPNRs(Math.round(totalPNRs));
    setTier(calculateTier(totalPNRs));
  }, [airTransactions, hotelTransactions]);

  const calculateTier = (pnrs) => {
    if (pnrs < 10000) return 'Small';
    if (pnrs < 50000) return 'Medium';
    return 'Large';
  };

  const getPrice = (feature) => {
    const tierIndex = tier === 'Small' ? 0 : tier === 'Medium' ? 1 : 2;
    return feature.prices[tierIndex];
  };

  const calculateTotal = () => {
    return Object.entries(selectedFeatures).reduce((total, [name, selected]) => {
      if (selected && !features.find(f => f.name === name).comingSoon) {
        const feature = features.find(f => f.name === name);
        return total + getPrice(feature) * (airTransactions + hotelTransactions);
      }
      return total;
    }, 0);
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <div>
            <h2 className="text-2xl font-bold mb-8">Enter your annual transaction volumes:</h2>
            <div className="mb-8">
              <label className="block mb-4 text-lg font-semibold">Air Transactions:</label>
              <Slider
                className="mb-4"
                min={100}
                max={100000}
                step={100}
                value={[airTransactions]}
                onValueChange={(value) => setAirTransactions(value[0])}
                style={{ "--slider-track-fill-color": "#4CAF50" }}
              />
              <Input
                type="number"
                value={airTransactions}
                onChange={(e) => setAirTransactions(Number(e.target.value))}
              />
            </div>
            <div className="mb-8">
              <label className="block mb-4 text-lg font-semibold">Hotel Transactions:</label>
              <Slider
                className="mb-4"
                min={100}
                max={100000}
                step={100}
                value={[hotelTransactions]}
                onValueChange={(value) => setHotelTransactions(value[0])}
                style={{ "--slider-track-fill-color": "#4CAF50" }}
              />
              <Input
                type="number"
                value={hotelTransactions}
                onChange={(e) => setHotelTransactions(Number(e.target.value))}
              />
            </div>
            <div className="bg-gray-100 p-4 rounded-lg mb-6">
              <p className="font-semibold">Estimated PNRs: {estimatedPNRs}</p>
              <p className="font-semibold">Your Category: {tier}</p>
              <p className="text-sm mt-2">Tier Thresholds:</p>
              <ul className="text-sm list-disc list-inside">
                <li>Small: 0 - 9,999 PNRs</li>
                <li>Medium: 10,000 - 49,999 PNRs</li>
                <li>Large: 50,000+ PNRs</li>
              </ul>
            </div>
            <Button onClick={() => setStep(1)}>Next</Button>
          </div>
        );
      case 1:
        return (
          <div>
            <h2 className="text-2xl font-bold mb-4">Select your features</h2>
            <div className="bg-gray-100 p-4 rounded-lg mb-6">
              <p className="font-semibold">Estimated PNRs: {estimatedPNRs}</p>
              <p className="font-semibold">Your Category: {tier}</p>
              <p className="text-sm mt-2">Tier Thresholds:</p>
              <ul className="text-sm list-disc list-inside">
                <li>Small: 0 - 9,999 PNRs</li>
                <li>Medium: 10,000 - 49,999 PNRs</li>
                <li>Large: 50,000+ PNRs</li>
              </ul>
            </div>
            {features.map((feature) => (
              <div key={feature.name} className={`mb-4 flex items-center ${feature.comingSoon ? 'opacity-50' : ''}`}>
                <Checkbox
                  id={feature.name}
                  checked={selectedFeatures[feature.name] || false}
                  onCheckedChange={(checked) =>
                    setSelectedFeatures(prev => ({ ...prev, [feature.name]: checked }))
                  }
                  disabled={feature.comingSoon}
                />
                <label htmlFor={feature.name} className={`ml-2 ${feature.comingSoon ? 'text-gray-500' : ''}`}>
                  {feature.name} {feature.comingSoon && '(Coming Soon)'}
                </label>
              </div>
            ))}
            <Button onClick={() => setStep(2)}>Calculate Total</Button>
          </div>
        );
      case 2:
        const total = calculateTotal();
        const quarterlyTotal = total / 4;
        const discountedTotal = total * 0.8;
        return (
          <div>
            <h2 className="text-2xl font-bold mb-4">Your Pricing Summary</h2>
            <p className="text-sm text-gray-700 mb-4 font-semibold">
              Paying per transaction is currently not supported so payment must be made either quarterly or at a discounted up-front annual rate.
            </p>
            <table className="w-full mb-4 border-collapse">
              <thead>
                <tr className="bg-gray-100">
                  <th className="border p-2 text-left">Feature</th>
                  <th className="border p-2 text-right">Price per Transaction</th>
                  <th className="border p-2 text-right">Annual Cost</th>
                </tr>
              </thead>
              <tbody>
                {features.map((feature) => (
                  selectedFeatures[feature.name] && (
                    <tr key={feature.name} className={feature.comingSoon ? "text-gray-400" : ""}>
                      <td className="border p-2">{feature.name} {feature.comingSoon && '(Coming Soon)'}</td>
                      <td className="border p-2 text-right">${getPrice(feature).toFixed(2)}</td>
                      <td className="border p-2 text-right">
                        ${(getPrice(feature) * (airTransactions + hotelTransactions)).toFixed(2)}
                      </td>
                    </tr>
                  )
                ))}
                <tr className="font-bold">
                  <td className="border p-2" colSpan={2}>Total Annual Cost</td>
                  <td className="border p-2 text-right">${total.toFixed(2)}</td>
                </tr>
                <tr className="font-semibold text-blue-600">
                  <td className="border p-2" colSpan={2}>Quarterly Cost</td>
                  <td className="border p-2 text-right">${quarterlyTotal.toFixed(2)}</td>
                </tr>
                <tr className="font-bold text-green-600">
                  <td className="border p-2" colSpan={2}>Discounted Annual Price (20% off)</td>
                  <td className="border p-2 text-right">${discountedTotal.toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
            <p className="text-sm text-gray-500 mb-2">* Prices for Safety and Risk and Sustainability are for preview only and not included in the total cost calculation.</p>
            <p className="text-sm text-gray-500 mb-4">** Implementation is included in the price.</p>
            <Button onClick={() => setStep(0)}>Start Over</Button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Card className="max-w-4xl mx-auto">
      <CardHeader>
        <h1 className="text-3xl font-bold">Travel Tech Pricing Calculator Wizard</h1>
      </CardHeader>
      <CardContent>{renderStep()}</CardContent>
    </Card>
  );
};

export default PricingCalculatorWizardAllFeatures;